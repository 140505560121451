import React, { useRef } from "react";
import "./App.css";
import Logo from "./components/Logo";
import NavBar from "./components/NavBar";
import {
  BrowserRouter as Router,
  Route,
  Link,
  HashRouter,
} from "react-router-dom";
import ImageHeader from "./components/ImageHeader";
import Services from "./components/Services";
import CountUp from "react-countup";
import { HashScroll } from "react-hash-scroll";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Offerings from "./components/Productions";

const Fade = require("react-reveal/Fade");
/*
Move counter tick up
Slide show of images
Animation of productions worked on
Contact form
*/
function App() {
  return (
    <HashRouter>
      <div className="App">
        <NavBar></NavBar>
        <HashScroll
          key={"home"}
          hash={"home"}
          behavior="smooth"
          position="start"
        >
          <header>
            <div className="row">
              <div className="col-md-6 p-0">
                <ImageHeader></ImageHeader>
              </div>
              <div className="col-md-6 p-0">
                <Fade right>
                  <div className="container headline-container">
                    <div className="title-container">
                      <h2>Specialists in Heavy Lifting</h2>{" "}
                      {/* <TypeAnimation
                      // Same String at the start will only be typed once, initially
                      sequence={[
                        "The UK's Leading Film Logistics Provider",
                        1000,
                        "The UK's Trusted Film Logistics Provider",
                        1000,
                        "The UK's Leading Film Logistics Provider",
                        1000,
                      ]}
                      speed={50} // Custom Speed from 1-99 - Default Speed: 40
                      // style={{ fontSize: "2em" }}
                      wrapper="span" // Animation will be rendered as a <span>
                      repeat={Infinity} // Repeat this Animation Sequence infinitely
                    /> */}
                    </div>
                    <div className="move-count-container">
                      <h3>Part of the SDL Group</h3>

                      <p>
                        Checkout SDL @{" "}
                        <a
                          target={"_blank"}
                          rel="noreferrer"
                          href="https://www.swingdriver.com"
                        >
                          https://swingdriver.com
                        </a>{" "}
                        to learn about our other services
                      </p>
                    </div>
                    <div className="row align-right">
                      <Link to={"#contact"}>
                        <button className="btn btn-primary btn-lg get-in-touch-btn">
                          Get In Touch
                        </button>
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </header>
        </HashScroll>
      </div>
      <Contact></Contact>
      <Footer></Footer>
    </HashRouter>
  );
}

export default App;
