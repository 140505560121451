import React, { useRef } from "react";
import Logo from "./Logo";
import { HashScroll } from "react-hash-scroll";
import "./Contact.css";
import ContactForm from "./ContactForm";
import Icon from "./Icon";
import "./Contact.css";

const Fade = require("react-reveal/Fade");
function Contact() {
  return (
    <div className="row">
      <div className="contact-container">
        <HashScroll
          key={"Contact"}
          hash={"contact"}
          behavior="smooth"
          position="start"
        >
          <div className="col">
            <h2>
              <Icon name="contact"></Icon>
              <span className="indent">We'd love to hear from you</span>
            </h2>
            <p>
              If you're a driver looking for work...{" "}
              <span className="email">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.swingdriver.com/recruitment/apply"
                >
                  Apply now
                </a>
              </span>
            </p>
            <p className="indent">
              ..or a Production that need's help with your moves{" "}
              <span className="email">planning@swingdriver.com</span>
            </p>
            {/* <br></br> */}
            {/* <hr></hr> */}
            {/* <ContactForm></ContactForm> */}
          </div>
        </HashScroll>
      </div>
    </div>
  );
}

export default Contact;
