import React, { useRef } from "react";
import Carousel from "react-bootstrap/Carousel";

function ImageHeader() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-03.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-04.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-05.jpg"
          alt="Second slide"
        />
      </Carousel.Item>{" "}
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-06.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-07.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-08.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-01.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className=""
          src="../images/header/img-02.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img className="" src="../images/minibus.jpg" alt="Second slide" />

        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img className="" src="../images/luton.jpg" alt="Second slide" />

        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img className="" src="../images/trailer-1.jpg" alt="Third slide" />

        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img className="" src="../images/trailer-2.jpg" alt="Fourth slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default ImageHeader;
