type IconProps = {
  name: String;
};

function Icon({ name }: IconProps) {
  return (
    <img src={"../images/icon/" + name + ".png"} className="icon" alt="icon" />
  );
}

export default Icon;
