import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import Socials from "./Socials";

const Fade = require("react-reveal/Fade");
/*
Move counter tick up
Slide show of images
Animation of productions worked on
Contact form
*/
function NavBar() {
  const [atTop, setTop] = useState(true);

  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    window.onscroll = () => {
      setTop(window.pageYOffset < 50);
    };

    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 992;

  console.log("mobile", isMobile);
  return (
    <div className="row">
      <div className="col-sm-6">
        <Navbar
          expand="lg"
          className={`navbar fixed-top navbar-expand-lg app-header ${
            !isMobile ? "" : "scrolled-nav"
          } ${atTop ? "" : "scrolled-nav"} `}
        >
          <Link to={"#home"}>
            <span className="navbar-brand">
              <Logo type="blue"></Logo>
            </span>
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {/* <ul className="navbar-nav mr-auto">
                <Link to={"#services"}>
                  <li className="nav-item">
                    <p className="nav-link">Our Services</p>
                  </li>
                </Link>
                <Link to={"#productions"}>
                  <li className="nav-item">
                    <p className="nav-link">Productions</p>
                  </li>
                </Link>
              </ul> */}
            </Nav>
            <Nav className="ml-auto">
              <div className="socials">
                <Socials></Socials>
              </div>

              <Link to={"#contact"}>
                <Fade right>
                  <button className="btn btn-primary get-in-touch-btn">
                    Get In Touch
                  </button>
                </Fade>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default NavBar;
