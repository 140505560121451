import { HashScroll } from "react-hash-scroll";
import Logo from "./Logo";
import "./Footer.css";
import Icon from "./Icon";
import Socials from "./Socials";

function Footer() {
  return (
    <HashScroll key={"Bottom"} hash={"bottom"} behavior="smooth" position="end">
      <div className="row footer-container">
        <hr></hr>
        <div className="col-3">
          <Logo type="orange"></Logo>
        </div>
        <div className="offset-6 col-3">
          <p className="copyright-text">© SDL Heavy Lifting Ltd</p>
          <p className="copyright-text">
            <Socials></Socials>
          </p>
        </div>
      </div>
    </HashScroll>
  );
}

export default Footer;
