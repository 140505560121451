import Icon from "./Icon";
const Fade = require("react-reveal/Fade");
function Socials() {
  return (
    <Fade right>
      <a href="https://www.linkedin.com/company/swing-driver-ltd">
        <Icon name="linkedin"></Icon>
      </a>
      <a href="https://www.facebook.com/Swingdrivers">
        <Icon name="facebook"></Icon>
      </a>
      <a href="https://instagram.com/swingdriver">
        <Icon name="instagram"></Icon>
      </a>
    </Fade>
  );
}

export default Socials;
